$(document).foundation();

function initialize2() {
  var mapCanvasScholen = document.getElementById('scholenmaps');
  if (mapCanvasScholen) {

    var styledMap = new google.maps.StyledMapType(
        {name: "Styled Map"});

    var mapOptions = {
        zoom: 12,
        zoomControl: true,
        scaleControl: true,
        scrollwheel: false,
        disableDoubleClickZoom: true,
        center: new google.maps.LatLng(51.1815253,5.9743854),
        mapTypeControlOptions: {
          mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style']
        }
    };

    var map = new google.maps.Map(mapCanvasScholen, mapOptions);
    var marker, image;

    image = stylesheet_directory+'/img/marker.png';
    var geocoder = new google.maps.Geocoder();

    var infowindow = new google.maps.InfoWindow();



    for( var i = 0; i < scholen.length; i++) {

      var title = scholen[i].title;

      marker = new google.maps.Marker({
          position: new google.maps.LatLng(scholen[i].lat, scholen[i].lng),
          map: map,
          title: title,
          zIndex: 1,
          icon: image
      });

      marker.info = new google.maps.InfoWindow({
        content: '<div id="content">'+
        '<div id="siteNotice">'+
        '</div>'+
        '<h1 id="firstHeading" style="margin: 0;">'+scholen[i].title+'</h1>'+
        '<div id="bodyContent">'+
        '<p> '+scholen[i].content+'</p>'+
        '</div>'+
        '</div>'
      });

      google.maps.event.addListener(marker, 'click', function() {
        this.info.open(map, this);
      });

    }


    map.mapTypes.set('map_style', styledMap);
    map.setMapTypeId('map_style');
  };
}

google.maps.event.addDomListener(window, 'load', initialize2);


$('.top-slider').slick({
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
});

var player;
$("#videomodal").on('closed.zf.reveal', function(){
  player.destroy();
});
$("#videomodal").on('open.zf.reveal', function(){
  playing = true;
  player = new YT.Player('ytplayer', {
      width: 600,
      height: 400,
      videoId: 'Q9w8Vh2ln5U',
      playerVars: {
          color: 'white',
          showinfo: 0,
          disablekb: 1,
          controls: 1,
          autoplay: 1,
          rel: 0
      }
  });
});

function initialize() {
    var mapCanvas = document.getElementById('maps');
    if (mapCanvas) {

  var styledMap = new google.maps.StyledMapType(
      {name: "Styled Map"});

  var mapOptions = {
      zoom: 16,
      zoomControl: true,
      scaleControl: true,
      scrollwheel: false,
      disableDoubleClickZoom: true,
      center: new google.maps.LatLng(51.1815253,5.9743854),
      mapTypeControlOptions: {
        mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style']
      }
  };

  var map = new google.maps.Map(mapCanvas, mapOptions);
    var marker1, image;
      image = stylesheet_directory+'/img/marker.png';
      marker1 = new google.maps.Marker({
          position: new google.maps.LatLng(51.1815253,5.9743854),
          map: map,
          title: 'Swalm & Roer',
          zIndex: 1,
          icon: image
      });
    map.mapTypes.set('map_style', styledMap);
    map.setMapTypeId('map_style');
  };
}

google.maps.event.addDomListener(window, 'load', initialize);

$(window).scroll(function () {
    var scrollTop = $(window).scrollTop();
    var height = $('.introduction').height();
    // $('.header-1').css({
    //     'opacity': ((height - (scrollTop-200)) / height)
    // });
    $('.introduction').css({
        'opacity': ((height - (scrollTop-400)) / height)
    });
});

$( document ).ready(function() {

  $('a.search').click(function(e){
    e.preventDefault();
    $('.searchform').toggleClass('show');
  });

  $('.introduction ul li a').click(function(e){
    e.preventDefault();
    var targetDiv = $(this).attr('href');
    $('html, body').animate({
        scrollTop: $(targetDiv).offset().top
    }, 1000);
  });

  /**
   * Cache
   */
  var $content = $('.banner .content-label')
    , $blur    = $('.banner .background-image')
    , $img1    = $('.image-collage img.img1')
    , $img2    = $('.image-collage img.img2')
    , $img3    = $('.image-collage img.img3')
    , $img4    = $('.image-collage img.img4')
    , wHeight  = $(window).height();

  $(window).on('resize', function(){
    wHeight = $(window).height();
  });

  /**
   * requestAnimationFrame Shim
   */
  window.requestAnimFrame = (function()
  {
    return  window.requestAnimationFrame       ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame    ||
            function( callback ){
              window.setTimeout(callback, 1000 / 60);
            };
  })();

  /**
   * Scroller
   */
  function Scroller()
  {
    this.latestKnownScrollY = 0;
    this.ticking            = false;
  }

  Scroller.prototype = {
    /**
     * Initialize
     */
    init: function() {
      window.addEventListener('scroll', this.onScroll.bind(this), false);
    },

    /**
     * Capture Scroll
     */
    onScroll: function() {
      this.latestKnownScrollY = window.scrollY;
      this.requestTick();
    },

    /**
     * Request a Tick
     */
    requestTick: function() {
      if( !this.ticking ) {
        window.requestAnimFrame(this.update.bind(this));
      }
      this.ticking = true;
    },

    /**
     * Update.
     */
    update: function() {
      var currentScrollY = this.latestKnownScrollY;
      this.ticking       = false;

      var slowScroll   = currentScrollY / 4
        , blurScroll   = currentScrollY * .7
        , slowImgOne   = currentScrollY / 20
        , slowImgTwo   = currentScrollY / 20
        , slowImgThree = currentScrollY / 20
        , slowImgFour  = currentScrollY / 20;

      $content.css({
        'transform'         : 'translateX(50%) translateY(-' + slowScroll + 'px)',
        '-moz-transform'    : 'translateX(50%) translateY(-' + slowScroll + 'px)',
        '-webkit-transform' : 'translateX(50%) translateY(-' + slowScroll + 'px)'
      });

      $img1.css({
        'transform'         : 'translateX(' + slowImgOne + 'px)',
        '-moz-transform'    : 'translateX(' + slowImgOne + 'px)',
        '-webkit-transform' : 'translateX(' + slowImgOne + 'px)'
      });

      $img2.css({
        'transform'         : 'translateX(-' + slowImgTwo + 'px)',
        '-moz-transform'    : 'translateX(-' + slowImgTwo + 'px)',
        '-webkit-transform' : 'translateX(-' + slowImgTwo + 'px)'
      });

      $img3.css({
        'transform'         : 'translateY(-50%) translateX(' + slowImgThree + 'px)',
        '-moz-transform'    : 'translateY(-50%) translateX(' + slowImgThree + 'px)',
        '-webkit-transform' : 'translateY(-50%) translateX(' + slowImgThree + 'px)'
      });

      $img4.css({
        'transform'         : 'translateX(-' + slowImgFour + 'px)',
        '-moz-transform'    : 'translateX(-' + slowImgFour + 'px)',
        '-webkit-transform' : 'translateX(-' + slowImgFour + 'px)'
      });

      $blur.css({
        'opacity' : blurScroll / wHeight
      });
    }
  };

  var scroller = new Scroller();
  scroller.init();

      var footerHeight = $('footer').outerHeight();
      // $('.container').css('margin-bottom', footerHeight + 'px');

      $("header .menu").click(function(){
        $('.menu-xl').addClass("is-active");
      });

      $(".menu-xl .close").click(function(){
        $('.menu-xl').removeClass("is-active");
      });

  });

$( window ).load(function() {
  $(document).foundation();

  var $grid = $('.grid').isotope({
    itemSelector: '.grid-item',
    percentPosition: true,
    masonry: {
      columnWidth: '.grid-item'
    }
  });
  // filter items on button click
  $('.filter-button-group').on( 'click', 'button', function() {
    $('.filter-button-group button').removeClass('active');
    $(this).addClass('active');
    var filterValue = $(this).attr('data-filter');
    $grid.isotope({ filter: filterValue });
  });
});
